import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    title: 'ACA Brazilian Jiu-Jitsu in Zürich, Oerlikon',
    loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'contact',
    title: 'Kontaktmöglichkeiten von ACA BJJ',
    loadComponent: () => import('./contact/contact.component').then((m) => m.ContactComponent),
  },
  {
    path: 'agenda',
    title: 'Stundenplan mit Klassenüberblick',
    loadComponent: () => import('./agenda/agenda.component').then((m) => m.AgendaComponent),
  },
  {
    path: 'gallery',
    title: 'Das Dojo, der Tempel. Die Galerie.',
    loadComponent: () => import('./gallery/gallery.component').then((m) => m.GalleryComponent),
  },
  {
    path: 'message',
    loadComponent: () => import('./message/message.component').then((m) => m.MessageComponent),
  },
  {
    path: 'prices',
    title: 'Unsere Preise für Kleine und Grosse',
    loadComponent: () => import('./prices/prices.component').then((m) => m.PricesComponent),
  },
  {
    path: 'impressum',
    title: 'Erreichbarkeiten ACA Brazilian Jiu-Jitsu',
    loadComponent: () => import('./impressum/impressum.component').then((m) => m.ImpressumComponent),
  },
  {
    path: 'privacy-policy',
    title: 'Datenschutz (Privacy Policy)',
    loadComponent: () => import('./privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
